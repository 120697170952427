<!-- eslint-disable no-debugger -->
<template>
    <div class="edit-meeting-component" id="" ref="EditMeetingRepository" tabindex="-1">
        <div v-if="spinner" class="overlay"></div>
        <p class="text-center mt-3" v-show="editing && !repository.meeting_date">
            Loading meeting repository details
            <i class="fa fa-spinner fa-spin"></i>
        </p>
        <form v-show="!editing || (editing && repository && repository.meeting_date)" @submit.prevent.stop="updateMeetingRepository">
            <div class="p-4 col-lg-12" id="edit-meeting-repository">
                <div v-show="sendError" class="alert alert-danger" role="alert">{{ formSubmitErrorMessage }}</div>
                <div class="row">
                    <div class="col">
                        <div class="row">
                            <div class="col-lg-4">
                                <div class="form-group mb-3">
                                    <label for="meeting-title" class="mb-2">Meeting name</label>
                                    <input type="text" class="form-control" v-model="repository.title" id="meeting-title" placeholder="Name of the meeting" />
                                </div>
                            </div>
                            <div class="col-lg-4">
                                <div class="form-group mb-3">
                                    <label for="dp-input-meeting-date" class="mb-2">Date</label>
                                    <Datepicker uid="meeting-date" v-model="repository.meeting_date" :format="format" :enableTimePicker="false" :autoApply="true" weekStart="0" />
                                </div>
                            </div>
                            <div class="col-lg-4">
                                <div class="form-group mb-3">
                                    <label for="meeting-category" class="mb-2">Category</label>
                                    <select class="form-control" v-model="repository.twd_repository_cat" id="meeting-category">
                                        <option value="null" disabled selected hidden>Select category</option>
                                        <option v-for="category in categories" :label="category.name" :selected="repository.twd_repository_cat === category.id" :value="category.id" :key="category.id">{{ category.name }}</option>
                                    </select>
                                </div>
                            </div>

                            <div class="tw-custom-col-lg-4 col-12">
                                <label class="mb-2">Documents</label>

                                <div class="row">
                                    <div class="col-lg-4 mb-3">
                                        <div class="tw-create-meeting-repository-modal-upload d-flex">
                                            <div class="row flex-grow-1">
                                                <div class="tw-create-meeting-repository-modal-upload-main col-7 d-flex justify-content-center flex-column">
                                                    <div class="ps-3">
                                                        <div>
                                                            <small>Agenda</small>
                                                        </div>
                                                        <span class="position-relative" v-if="repository.agenda">
                                                            <a :href="repository.agenda.source_url || repository.agenda.guid" class="tw-red-color text-decoration-none" target="_blank">
                                                                {{ sliceString(repository.agenda.filename, 15) }}
                                                            </a>
                                                            <span class="tw-close-icon tw-red-color cursor-pointer position-absolute top-0" @click="handleAgendaAttachmentRemove">
                                                                <i class="far fa-times-circle"></i>
                                                            </span>
                                                        </span>
                                                        <span v-if="!repository.agenda">Upload document</span>
                                                    </div>
                                                </div>
                                                <div class="col-5 d-flex justify-content-end align-items-center">
                                                    <input ref="agenda" type="file" id="agenda" class="form-control d-none" @change="handleFileUpload($event, 'agenda')" />
                                                    <label class="tw-icon tw-light-grey-bg-color d-flex justify-content-center align-items-center cursor-pointer me-1" for="agenda"><i class="fas fa-paperclip"></i></label>
                                                    <span v-if="siteType != 'cms6'" class="tw-icon tw-light-grey-bg-color d-flex justify-content-center align-items-center cursor-pointer me-2" @click="mediaLib($event, 'agenda')"
                                                        ><i class="far fa-image"></i
                                                    ></span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-4 mb-3">
                                        <div class="tw-create-meeting-repository-modal-upload d-flex">
                                            <div class="row flex-grow-1">
                                                <div class="tw-create-meeting-repository-modal-upload-main col-7 d-flex justify-content-center flex-column">
                                                    <div class="ps-3">
                                                        <div>
                                                            <small>Agenda packet</small>
                                                        </div>
                                                        <span class="position-relative" v-if="repository.agenda_pack">
                                                            <a :href="repository.agenda_pack.source_url || repository.agenda_pack.guid" class="tw-red-color text-decoration-none" target="_blank">
                                                                {{ sliceString(repository.agenda_pack.filename, 15) }}
                                                            </a>
                                                            <span class="tw-close-icon tw-red-color cursor-pointer position-absolute top-0" @click="repository.agenda_pack = 0">
                                                                <i class="far fa-times-circle"></i>
                                                            </span>
                                                        </span>
                                                        <span v-if="!repository.agenda_pack">Upload document</span>
                                                    </div>
                                                </div>
                                                <div class="col-5 d-flex justify-content-end align-items-center">
                                                    <input ref="agenda-packet" type="file" id="agenda-packet" class="form-control d-none" @change="handleFileUpload($event, 'agenda-packet')" />
                                                    <label class="tw-icon tw-light-grey-bg-color d-flex justify-content-center align-items-center cursor-pointer me-1" for="agenda-packet"><i class="fas fa-paperclip"></i></label>
                                                    <span v-if="siteType != 'cms6'" class="tw-icon tw-light-grey-bg-color d-flex justify-content-center align-items-center cursor-pointer me-2" @click="mediaLib($event, 'agenda-packet')"
                                                        ><i class="far fa-image"></i
                                                    ></span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-4 mb-3">
                                        <div class="tw-create-meeting-repository-modal-upload d-flex">
                                            <div class="row flex-grow-1">
                                                <div class="tw-create-meeting-repository-modal-upload-main col-7 d-flex justify-content-center flex-column">
                                                    <div class="ps-3">
                                                        <div>
                                                            <small>Meeting minutes</small>
                                                        </div>
                                                        <span class="position-relative" v-if="repository.meeting_minutes">
                                                            <a :href="repository.meeting_minutes.source_url || repository.meeting_minutes.guid" class="tw-red-color text-decoration-none" target="_blank">
                                                                {{ sliceString(repository.meeting_minutes.filename, 15) }}
                                                            </a>
                                                            <span class="tw-close-icon tw-red-color cursor-pointer position-absolute top-0" @click="repository.meeting_minutes = 0">
                                                                <i class="far fa-times-circle"></i>
                                                            </span>
                                                        </span>
                                                        <span v-if="!repository.meeting_minutes">Upload document</span>
                                                    </div>
                                                </div>
                                                <div class="col-5 d-flex justify-content-end align-items-center">
                                                    <input ref="meeting-minutes" type="file" id="meeting-minutes" class="form-control d-none" @change="handleFileUpload($event, 'meeting-minutes')" />
                                                    <label class="tw-icon tw-light-grey-bg-color d-flex justify-content-center align-items-center cursor-pointer me-1" for="meeting-minutes"><i class="fas fa-paperclip"></i></label>
                                                    <span v-if="siteType != 'cms6'" class="tw-icon tw-light-grey-bg-color d-flex justify-content-center align-items-center cursor-pointer me-2" @click="mediaLib($event, 'meeting-minutes')"
                                                        ><i class="far fa-image"></i
                                                    ></span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="tw-custom-col-lg-4 col-12">
                                <label class="mb-2">Additional Documents</label>
                                <div class="row">
                                    <div class="col-lg-4 mb-3">
                                        <div class="tw-create-meeting-repository-modal-upload d-flex">
                                            <div class="row flex-grow-1">
                                                <div class="tw-create-meeting-repository-modal-upload-main col-7 d-flex justify-content-center flex-column">
                                                    <div class="ps-3">
                                                        <div>
                                                            <small>Additional File</small>
                                                        </div>
                                                        <span class="position-relative" v-if="repository.additional_file">
                                                            <a :href="repository.additional_file.source_url || repository.additional_file.guid" class="tw-red-color text-decoration-none" target="_blank">
                                                                {{ sliceString(repository.additional_file.filename, 15) }}
                                                            </a>
                                                            <span class="tw-close-icon tw-red-color cursor-pointer position-absolute top-0" @click="repository.additional_file = 0">
                                                                <i class="far fa-times-circle"></i>
                                                            </span>
                                                        </span>
                                                        <span v-if="!repository.additional_file">Upload document</span>
                                                    </div>
                                                </div>
                                                <div class="col-5 d-flex justify-content-end align-items-center">
                                                    <input ref="additional-file" type="file" id="additional-file" class="form-control d-none" @change="handleFileUpload($event, 'additional-file')" />
                                                    <label class="tw-icon tw-light-grey-bg-color d-flex justify-content-center align-items-center cursor-pointer me-1" for="additional-file"><i class="fas fa-paperclip"></i></label>
                                                    <span v-if="siteType != 'cms6'" class="tw-icon tw-light-grey-bg-color d-flex justify-content-center align-items-center cursor-pointer me-2" @click="mediaLib($event, 'additional-file')"
                                                        ><i class="far fa-image"></i
                                                    ></span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-4 mb-3">
                                        <div class="form-floating">
                                            <input type="text" class="form-control" id="additional-url" v-model="repository.additional_url" />
                                            <label for="additional-url">Additional URL</label>
                                        </div>
                                    </div>
                                    <div class="col-lg-4 mb-3">
                                        <div class="form-floating">
                                            <input type="text" class="form-control" id="additional-doc" v-model="repository.additional_doc" />
                                            <label for="additional-doc">Additional Public Name</label>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-12">
                                <label class="mb-3">External Websites</label>
                                <div class="row">
                                    <div class="col-lg-6">
                                        <div class="form-group mb-3">
                                            <label for="meeting-youtube-url" class="mb-2 fw-light">Youtube URL</label>
                                            <input type="text" class="form-control" v-model="repository.video" id="meeting-youtube-url" />
                                        </div>
                                    </div>
                                    <div class="col-lg-6">
                                        <div class="form-group mb-3">
                                            <label for="meeting-soundcloud-url" class="mb-2 fw-light">SoundCloud / Dropbox URL</label>
                                            <input type="text" class="form-control" v-model="repository.sound" id="meeting-soundcloud-url" />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-12 mb-5">
                                <div class="form-group">
                                    <label for="meeting-notes" class="mb-2">Notes</label>
                                    <textarea rows="4" id="meeting-notes" class="form-control" v-model="repository.notes" placeholder="Describe your meeting"></textarea>
                                </div>
                            </div>
                            <div class="col-12 mb-3" v-if="repository.tw_add_event && hasHeyGovWithVenues && heyGovVenues.length">
                                <div class="form-group col">
                                    <label class="mb-2" for="event-repeat">HeyGov Venue</label>
                                    <Multiselect
                                        v-model="heyGovBlockedVenues"
                                        mode="tags"
                                        class="tw-delivery-method-select"
                                        :close-on-select="false"
                                        :searchable="false"
                                        :create-option="false"
                                        :hideSelected="false"
                                        :options="heyGovVenues"
                                        placeholder="-- Choose Venues --"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="modal-footer" :class="mediaLibOpened ? 'col-8' : 'col-12'">
                <div class="tw-checkbox col d-flex align-items-center">
                    <input id="tw-add-event-auto" type="checkbox" v-model="repository.tw_add_event" />
                    <label class="fw-bold" for="tw-add-event-auto">Add to Calendar</label>
                    <div v-if="repository.tw_add_event" class="d-flex">
                        <Datepicker class="tw-add-event-auto-time ms-1" :format="formatTime" hideInputIcon v-model="eventStartTime" :timePicker="true" :is24="false" placeholder="Start time" :required="true" />
                        <Datepicker class="tw-add-event-auto-time ms-1" :format="formatTime" hideInputIcon v-model="eventEndTime" :timePicker="true" :is24="false" placeholder="End time" :required="true" />
                    </div>
                </div>
                <button v-if="subscribedToMinutes && !isAgendaRemoved && !repository.meeting_minutes && repository.state !== 'upcoming' && siteType === 'cms6'" @click="handleNextClick" class="btn px-5 tw-red-bg-color text-white">
                    Next
                    <span v-if="spinner"><i class="fas fa-spinner fa-spin"></i></span>
                </button>
                <button v-else class="px-5 btn tw-red-bg-color text-white">
                    Save
                    <span v-if="spinner"><i class="fas fa-spinner fa-spin"></i></span>
                </button>
                <router-link class="text-decoration-none" to="/meeting-repository">
                    <button class="btn tw-dark-grey-bg-color text-white" type="button">Cancel</button>
                </router-link>
            </div>
        </form>
    </div>
    <div v-if="spinner" class="spinner">
        <span class="ms-2">
            <i class="fa fa-spinner fa-spin fa-lg"></i>
        </span>
    </div>
</template>
<script>
// import wordpressMixin from "@/mixins/wordpressMixin";
import wordpressMeetingRepositoryMixin from "@/mixins/wordpressMeetingRepositoryMixin";
import heyGovMeetingRepositoryMixin from "@/mixins/heyGovMeetingRepositoryMixin";
import helperMixin from "@/mixins/helperMixin";
import userMixin from "@/mixins/userMixin";
import heyGovMixin from "@/mixins/heyGovMixin";
import Multiselect from "@vueform/multiselect";
import wordpressMixin from "@/mixins/wordpressMixin";
import { mapState } from "vuex";

import router from "../../router";

let site = localStorage.getObject("site");

let mixins;

if ((site.type == "wordpress" && site.supportsHeyGovMeetRepo) || site.type == "cms6") {
    mixins = [heyGovMeetingRepositoryMixin, helperMixin, heyGovMixin, userMixin];
} else {
    mixins = [wordpressMeetingRepositoryMixin, helperMixin, heyGovMixin, userMixin, wordpressMixin];
}

export default {
    name: "EditMeetingRepository",
    components: {
        Multiselect,
    },
    emits: ["modalHidden", "repositorySaved", "showClerkMinutesPopup", "setParentRepo", "proceed-to-minutes", "agenda-removed", "proceed-to-transcript", "agendaItemsNotPresent", "mediaFileIsNotPresent"],
    data: () => ({
        modal: null,
        subject: "",
        message: "",
        spinner: false,
        uploadSpinner: false,
        repository: {},
        tempRepository: {},
        title: "",
        meeting_date: new Date(),
        format: "LLL dd, yyyy",
        formatTime: "hh:mm a",
        categories: null,
        twd_repository_cat: null, //vm.meetingRepository.twd_repository_cat[0].id
        selectedCategoryId: null,
        type: null,
        agenda: 0,
        agendaPacket: 0,
        agendaItems: [],
        meetingMinutes: 0,
        additionalFile: 0,
        additionalUrl: "",
        additionalDoc: "",
        video: "",
        sound: "",
        notes: "",
        addEventAuto: false,
        mediaLibOpened: false,
        event: {},
        eventStartTime: null,
        eventEndTime: null,
        sendError: false,
        heyGovVenues: [],
        heyGovBlockedVenues: [],
        heyGovEvent: null,
        formSubmitErrorMessage: "",
        isFormValidated: false,
        meetingRepoId: "",
        isAgendaRemoved: false,
    }),
    mounted() {
        this.setMountedStateDefaults(true);
        this.heyGovJurisdiction();
        this.meetingRepoId = this.$route.params.id;

        if (!this.subscribedToMinutes) setTimeout(() => this.$emit("showClerkMinutesPopup", true), 500);
    },

    watch: {
        meetingRepoId() {
            if (this.meetingRepoId) this.setRepository();
        },
        subscribedToMinutes() {
            if (this.repository.agenda_items?.length === 0 && this.repository.agenda) this.checkAgendaFile();
        },
    },
    computed: {
        ...mapState(["subscribedToMinutes"]),

        hasHeyGovWithVenues() {
            return this.isHeyGovClientWithVenues();
        },
        siteType() {
            return this.getSiteType();
        },
    },
    methods: {
        handleNextClick(event) {
            event.preventDefault();
            this.$emit("proceed-to-minutes", this.repository);
        },
        validateForm() {
            if (!this.repository.title) {
                this.showValidatingMessage("Meeting name is required.");
                return;
            } else if (!this.repository.twd_repository_cat) {
                this.showValidatingMessage("Category have to be selected.");
                return;
            } else {
                this.sendError = false;
                this.isFormValidated = true;
            }
        },
        showValidatingMessage(message) {
            this.sendError = true;
            this.formSubmitErrorMessage = message;
            this.isFormValidated = false;
        },
        async checkAgendaFile() {
            this.spinner = true;
            this.processAgenda(this.repository)
                .then((response) => {
                    if (response.status === 201 || response.status === 200) {
                        this.updateMeetingRepository(false).then((response) => {
                            if (response.status === 200 || response.status === 201) {
                                this.setRepository();
                                setTimeout(() => (this.spinner = false), 300);
                            }
                        });
                        if (!this.subscribedToMinutes) this.$emit("showClerkMinutesPopup");
                    } else {
                        if (this.repository?.agenda) this.handleAgendaAttachmentRemove();
                        this.handleFileUploadErrors(response);
                    }
                    return response;
                })
                .catch((error) => {
                    // remove agenda if file is not correct
                    if (this.repository?.agenda) this.handleAgendaAttachmentRemove();
                    this.handleFileUploadErrors(error);
                });
        },
        setMountedStateDefaults(value) {
            if (value) {
                this.setCategories();
                this.filesToUpload = [];
            }
            if (this.hasHeyGovWithVenues) {
                // cleaning heyGov venues array
                this.heyGovVenues = [];
                this.setHeyGovVenues();
            }
        },
        handleAgendaAttachmentRemove() {
            this.isAgendaRemoved = true;
            this.repository.agenda = 0;
            this.repository.agenda_items = [];
            if (this.subscribedToMinutes) {
                this.updateMeetingRepository(false).then(() => {
                    this.$emit("agenda-removed");
                });
            }
        },
        async setCategories() {
            let res = await this.getMeetingRepositoryCategories();
            this.categories = res.data;
        },
        handleMediaLibOpened() {
            this.mediaLibOpened = !this.mediaLibOpened;
        },
        mediaLib(event, type = null) {
            this.type = type;
            if (this.mediaLibOpened) {
                this.mediaLibOpened = false;
            } else {
                let allowMulti = false;
                let requestingFile = true;
                let chooseFeaturedImg = false;
                this.$refs.mediaLibrary.mediaLibOpen(true, requestingFile, allowMulti, chooseFeaturedImg);
                this.mediaLibOpened = true;
            }
        },
        async handleFileUpload(event, type) {
            let res = await this.uploadFile(event.target.files[0], type);
            this.uploadSpinner = true;
            if (type === "agenda") {
                this.repository.agenda = await res.data;
                this.repository.agenda.filename = this.repository.agenda.source_url.split("/").pop();
                if (this.subscribedToMinutes && this.siteType === "cms6") this.checkAgendaFile();
            } else if (type === "agenda-packet") {
                this.repository.agenda_pack = await res.data;
                this.repository.agenda_pack.filename = this.repository.agenda_pack.source_url.split("/").pop();
                // if (this.agendaPacket.source_url) {}
            } else if (type === "meeting-minutes") {
                this.repository.meeting_minutes = await res.data;
                this.repository.meeting_minutes.filename = this.repository.meeting_minutes.source_url.split("/").pop();
                // if (this.meetingMinutes.source_url) {}
            } else if (type === "additional-file") {
                this.repository.additional_file = await res.data;
                this.repository.additional_file.filename = this.repository.additional_file.source_url.split("/").pop();
                // if (this.additionalFile.source_url) {}
            }
            this.uploadSpinner = false;
        },
        async setHeyGovVenues() {
            let res = await this.getHeyGovVenues();
            let self = this;

            res.forEach(function (venue) {
                let tmp = {};

                tmp.value = venue.id;
                tmp.label = venue.name;
                self.heyGovVenues.push(tmp);
            });
        },
        setRepositoryFile(file) {
            if (this.type === "agenda") {
                this.repository.agenda = file;
            } else if (this.type === "agenda-packet") {
                this.repository.agenda_pack = file;
            } else if (this.type === "meeting-minutes") {
                this.repository.meeting_minutes = file;
            } else if (this.type === "additional-file") {
                this.repository.additional_file = file;
            }
        },
        handleFileUploadErrors(objResponse) {
            if (!objResponse) return;
            // Handle all input fields based on response status:
            for (const key in objResponse) {
                if (objResponse[key].status === 400 || objResponse[key].status === 401) {
                    console.error(objResponse[key].message);
                    this.spinner = false;
                    this.$swal({
                        icon: "error",
                        text: objResponse[key].message,
                        confirmButtonText: "Try Again",
                    });
                }
            }
        },
        onFormCancellation() {
            this.repository = {};
            router.push("/meeting-repository");
        },
        async updateMeetingRepository(showStatusPopup = true) {
            this.validateForm();
            if (!this.isFormValidated) return;
            this.spinner = true;

            let res;
            let catTemp = [];
            catTemp.push(this.repository.twd_repository_cat);
            this.repository.twd_repository_cat = catTemp;

            if (this.repository.agenda) this.repository.agenda = this.repository.agenda.id || this.repository.agenda.ID || this.repository.agenda;
            if (this.repository.agenda_pack) this.repository.agenda_pack = this.repository.agenda_pack.id || this.repository.agenda_pack.ID || this.repository.agenda_pack;
            if (this.repository.meeting_minutes) this.repository.meeting_minutes = this.repository.meeting_minutes.id || this.repository.meeting_minutes.ID || this.repository.meeting_minutes;
            if (this.repository.additional_file) this.repository.additional_file = this.repository.additional_file.id || this.repository.additional_file.ID || this.repository.additional_file;
            this.repository.meeting_date = this.moment(this.repository.meeting_date).format("YYYY-MM-DD");

            res = await this.updateRepository(this.repository);

            if (res.status === 201 || res.status === 200) {
                if (this.repository.tw_add_event) {
                    this.buildEvent();

                    if (!this.hasHeyGovEvents) {
                        this.saveEvent(this.event);
                    } else {
                        this.saveHeyGovEvent(this.heyGovEvent);
                    }
                }

                if (this.siteType == "wordpress") {
                    this.spinner = false;
                    this.$emit("repositorySaved");
                }

                // go back on meetings list after successful meeting add
                if (showStatusPopup) {
                    router.push("/meeting-repository");
                    this.spinner = false;
                    this.$swal({
                        toast: true,
                        showConfirmButton: true,
                        title: "Meeting is successfully edited!",
                        text: "",
                        position: "center",
                        icon: "icon",
                        timer: 4000,
                    });
                }
                return res;
            } else {
                this.sendError = true;
                this.formSubmitErrorMessage = "Error: Repository is not edited.";
            }
        },
        async setRepository() {
            let res = await this.getMeetingRepository(this.meetingRepoId);
            this.repository = await res.data;
            this.agendaItems = this.subscribedToMinutes && this.siteType === "cms6" ? this.repository.agenda_items : [];
            this.repository.title = this.decodeHTMLEntities(this.repository.title.rendered);
            this.repository.twd_repository_cat = this.repository.twd_repository_cat[0] || this.repository.categories[0];
            this.repository.tw_add_event = false;
            this.repository.meeting_date = this.sliceString(this.repository.meeting_date, 10) + " 00:00"; // customize date string by adding time (if there is time already remove it and return only date in format 2022-08-16) to fix JS time zone handler, for example when showing 2022-08-15 if meeting date is 2022-08-16

            if (this.repository.agenda.guid) this.repository.agenda.filename = this.repository.agenda.guid.split("/").pop();
            if (this.repository.agenda_pack.guid) this.repository.agenda_pack.filename = this.repository.agenda_pack.guid.split("/").pop();
            if (this.repository.meeting_minutes.guid) this.repository.meeting_minutes.filename = this.repository.meeting_minutes.guid.split("/").pop();
            if (this.repository.additional_file.guid) this.repository.additional_file.filename = this.repository.additional_file.guid.split("/").pop();
            this.$emit("setParentRepo", this.repository);
            this.isAgendaRemoved = false;
        },
        buildEvent() {
            if (!this.hasHeyGovEvents) {
                this.event.title = this.repository.title;
                this.event.description = this.repository.notes;
                this.event.start_date = this.repository.meeting_date ? this.moment(this.repository.meeting_date).format("YYYY-MM-DD ") : this.moment().format("YYYY-MM-DD ");
                this.event.start_date = this.event.start_date + this.moment(this.eventStartTime).format("HH:mm:ss");
                // this.event.start_date = this.repository.meeting_date ? this.moment(this.repository.meeting_date).format('YYYY-MM-DD HH:mm:ss') : this.moment().format('YYYY-MM-DD HH:mm:ss');
                this.event.end_date = this.repository.meeting_date ? this.moment(this.repository.meeting_date).format("YYYY-MM-DD ") : this.moment().format("YYYY-MM-DD ");
                this.event.end_date = this.event.end_date + this.moment(this.eventEndTime).format("HH:mm:ss");
                // this.event.end_date = this.repository.meeting_date ? this.moment(this.repository.meeting_date).add(1, 'hours').format('YYYY-MM-DD HH:mm:ss') : this.moment().add(1, 'hours').format('YYYY-MM-DD HH:mm:ss');
                this.event.status = "publish";
                this.event.categories = [0];
                this.event.all_day = false;
                this.event.end = null;
                this.event.id = null;
                this.event.recurrence = null;
                if (this.hasHeyGovWithVenues) {
                    this.event.heygov_venues = this.heyGovBlockedVenues.join();
                }
            } else {
                this.heyGovEvent = {
                    name: this.repository.title,
                    description: this.repository.notes || "",
                    metadata: { hide_end_time: false },
                };
                this.heyGovEvent.starts_at_local = this.repository.meeting_date ? this.moment(this.repository.meeting_date).format("YYYY-MM-DD ") : this.moment().format("YYYY-MM-DD ");
                this.heyGovEvent.starts_at_local = this.heyGovEvent.starts_at_local + this.moment(this.eventStartTime).format("HH:mm:ss");
                this.heyGovEvent.ends_at_local = this.repository.meeting_date ? this.moment(this.repository.meeting_date).format("YYYY-MM-DD ") : this.moment().format("YYYY-MM-DD ");
                this.heyGovEvent.ends_at_local = this.heyGovEvent.ends_at_local + this.moment(this.eventEndTime).format("HH:mm:ss");

                if (this.hasHeyGovWithVenues) {
                    this.heyGovEvent.venues = this.heyGovBlockedVenues;
                }
            }
        },
        async saveHeyGovEvent(event) {
            // Create new HG event
            let res = await this.axios.post(`${this.apiRoute}/${this.domain}/events?apiKey=${this.apiKey}`, event);
            if (res && res.status === 200) {
                await this.axios.post(
                    this.cms6Url + `clients/regenerate/events`,
                    {},
                    {
                        //http://localhost/tw/milan/wp-json/wp/v2/twd_repository
                        headers: this.headers,
                    }
                );
            }
        },
    },
    props: {
        editing: Boolean,
        editingMeetingRepositoryId: [Number, String],
        list: Array,
        meetingRepo: Object,
    },
    mixins: mixins,
};
</script>

<style scoped>
#edit-meeting-repository,
.modal-footer {
    max-width: 80%;
    margin: 0 auto;
}

.tw-create-meeting-repository-modal-upload-main a {
    word-break: keep-all;
    white-space: nowrap;
}
.tw-create-meeting-repository-modal-upload-main span.tw-close-icon {
    right: -20px;
}
.tw-checkbox label {
    margin-left: 35px;
}
.tw-checkbox label::before {
    top: calc(50% - 12px);
    left: -35px;
}
.tw-checkbox label::after {
    top: calc(50% - 6px);
    left: -29px;
}
.tw-add-event-auto-time {
    max-width: 120px;
}
.form-group input,
.form-group textarea {
    height: 45px !important;
    border-radius: 12px;
}
.spinner {
    font-size: 5.5rem;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #fff;
    z-index: 3;
    text-align: center;
}
.spinner span {
    font-size: 5rem;
}
.spinner svg {
    color: #fff;
}
.overlay {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 2;
    cursor: pointer;
}
input#dp-input-meeting-date {
    height: 45px !important;
}
@media only screen and (min-width: 992px) {
    .tw-custom-col-lg-4 .col-lg-4:first-child {
        padding-right: 4px;
    }
    .tw-custom-col-lg-4 .col-lg-4:nth-child(2) {
        padding-right: 2px;
        padding-left: 2px;
    }
    .tw-custom-col-lg-4 .col-lg-4:last-child {
        padding-left: 4px;
    }
}
</style>
